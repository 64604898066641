












































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { GridRow } from '@/types/cms/components';

/**
 * CompanyInformationFormDialog component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component
export default class HomeCompanyInformationFormDialog extends Vue {
    // Prop of type boolean that defined whether the dialog should be shown or not
    @Prop(Boolean) readonly showDialog: boolean | undefined;

    // Prop that holds the companyInformation data
    @Prop({type: Object as () => GridRow}) readonly editedCompanyInformationItem: GridRow | undefined;

    // Prop that holds the id of the edited companyInformation
    @Prop(Number) readonly editedCompanyInformationIndex: number | undefined;

    // An crud string that defines the mode of the dialog
    @Prop(String) readonly dialogMode: string | undefined;

    // Boolean that defines wheter the form data is valid or not
    private formValid = false;

    /**
     * @returns the title for the form
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    get formTitle(): string {
        return (this.dialogMode) ? this.$t('form.titles.' + this.dialogMode + 'CompanyInformation').toString() : '';
    }

    /**
     * Resets the form validation
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private resetFormValidation() {
        if (this.$refs.form) {
            (this.$refs.form as Vue & { resetValidation: () => void }).resetValidation();
        }
    }

    /**
     * Saves/Updates or deletes the companyInformation
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private saveCompanyInformation() {
        if (this.editedCompanyInformationItem) {
            this.resetFormValidation();
            this.$emit('companyInformationSaved', this.dialogMode, this.editedCompanyInformationIndex, this.editedCompanyInformationItem);
        }
    }

    /**
     * Closes the dialog
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private closeDialog() {
        this.resetFormValidation();
        this.$emit('dialogClosed');
    }
}
